/**
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.1
 */
//@ts-check
import { AnyAction } from 'redux'
import { IRemediationInfoResponse } from '../types/IRemediationInfoResponse'

export interface IRemediationState {
  fetching: boolean
  posting: boolean
  error: boolean
  success: boolean
  data: IRemediationInfoResponse
}

export enum RemediationActionType {
  PostingRemediation = 'POSTING_REMEDIATION',
  FetchingLenderWorkspace = 'FETCHING_LENDER_WORKSPACE',
  FetchingRemediation = 'FETCHING_REMEDIATION',
  PostedRemediation = 'POSTED_REMEDIATION',
  FetchedRemediation = 'FETCHED_REMEDIATION'
}

export interface RemediationAction extends AnyAction {
  type: RemediationActionType
  data: IRemediationInfoResponse
}

const initialState = {
  fetching: false,
  posting: false,
  error: false
}

export const remediationReducer = (state = initialState, { type, data }: RemediationAction) => {
  switch (type) {
    case RemediationActionType.PostingRemediation:
      return {
        ...state,
        posting: true,
        success: false,
        error: false
      }
    case RemediationActionType.FetchingLenderWorkspace:
    case RemediationActionType.FetchingRemediation:
      return {
        ...state,
        fetching: true,
        success: false,
        error: false
      }
    case RemediationActionType.PostedRemediation:
      return {
        ...state,
        data,
        posting: false,
        success: true
      }
    case RemediationActionType.FetchedRemediation:
      return {
        ...state,
        data,
        fetching: false
      }
    default:
      return state
  }
}
