/**
 * @prettier
 * @author Alexander Nagelberg
 * @since 24.1
 **/
//@ts-check

import { combineReducers } from 'redux'
import { apiInfoReducer } from '../api-information/reducers/apiInfoReducer'
import { fundingFeeReducer } from '../funding-fee/reducers/fundingFeeReducer'
import { remediationReducer } from '../remediation/reducers/remediationReducer'

const lenderReducer = combineReducers({
  apiInfo: apiInfoReducer,
  fundingFee: fundingFeeReducer,
  remediation: remediationReducer
})

export default lenderReducer
